import {IIconProps} from '../DataStoreIcon';

const Lightstep = ({width = '20', height = '20'}: IIconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1084_22108)">
      <path
        d="M11.4399 11.5048C13.0079 11.5048 14.2719 10.2408 14.2719 8.67282C14.2719 7.10482 13.0079 5.84082 11.4399 5.84082C9.87191 5.84082 8.60791 7.10482 8.60791 8.67282C8.62391 10.2408 9.88791 11.5048 11.4399 11.5048Z"
        fill="#009B48"
      />
      <path
        d="M10.672 0.0488489L1.43999 4.99285C1.37599 5.00885 1.37599 5.07285 1.35999 5.13685L-0.0640081 11.4728C-0.0800081 11.5688 -0.0480077 11.6328 0.0639924 11.6808L12.032 17.3128C12.112 17.3608 12.192 17.3608 12.272 17.3128L19.952 13.8408C20.032 13.7928 20.08 13.7128 20.096 13.6648L22.912 0.992848C22.928 0.896848 22.864 0.784849 22.768 0.768849H22.752L10.848 0.0488489C10.768 0.000848942 10.704 0.0168489 10.672 0.0488489ZM12.048 16.9768L0.847993 11.7128H4.99199L14.208 13.0248L12.048 16.9768ZM14.592 13.0568L19.312 13.7288L12.512 16.8168L14.592 13.0568ZM5.27999 11.3768L8.79999 4.46485L19.632 3.21685L14.4 12.6888L5.27999 11.3768ZM19.856 2.86485L8.95999 4.11285L10.864 0.368849L22.256 1.04085L19.856 2.86485ZM8.55999 4.17685L2.36799 4.89685L10.368 0.608849L8.55999 4.17685ZM1.63199 5.29685L8.36799 4.51285L4.89599 11.3288H0.287992L1.63199 5.29685ZM19.776 13.4568L14.752 12.7368L20.048 3.12085L22.48 1.28085L19.776 13.4568Z"
        fill="#121C22"
      />
    </g>
    <defs>
      <clipPath id="clip0_1084_22108">
        <rect width="24" height="18.24" fill="white" transform="translate(0 0.000976562)" />
      </clipPath>
    </defs>
  </svg>
);

export default Lightstep;
