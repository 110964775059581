import {IIconProps} from '../DataStoreIcon';

const Dynatrace = ({width = '20', height = '20'}: IIconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1084_22356)">
      <path
        d="M8.14417 2.0102C7.83572 3.63813 7.45872 6.05432 7.25309 8.50479C6.89323 12.8231 7.116 15.7191 7.116 15.7191L1.03268 21.494C1.03268 21.494 0.570004 18.2552 0.330099 14.6052C0.193013 12.3433 0.141602 10.3555 0.141602 9.15596C0.141602 9.08742 0.175873 9.01887 0.175873 8.95033C0.175873 8.86465 0.278688 8.05925 1.06695 7.30526C1.92375 6.48273 8.24698 1.53039 8.14417 2.0102"
        fill="#3A8ACA" />
      <path
        d="M8.14417 2.01103C7.83572 3.63896 7.45873 6.05516 7.25309 8.50562C7.25309 8.50562 0.518596 7.70022 0.141602 9.32816C0.141602 9.24248 0.261552 8.24858 1.04983 7.49459C1.90662 6.67206 8.24699 1.53122 8.14418 2.01103"
        fill="#262424" />
      <path
        d="M0.141641 8.9336V9.31059C0.210184 9.01928 0.330139 8.81365 0.570045 8.48806C1.06699 7.85402 1.87239 7.68266 2.19798 7.64839C3.84304 7.42562 6.27637 7.16858 8.72684 7.10003C13.0623 6.96295 15.924 7.3228 15.924 7.3228L22.0073 1.54793C22.0073 1.54793 18.82 0.948167 15.1872 0.519782C12.8052 0.228468 10.7146 0.0742424 9.53224 0.00569752C9.44656 0.00569752 8.60688 -0.0971172 7.81862 0.656871L0.861359 7.27139C0.0730981 8.02538 0.141641 8.86505 0.141641 8.9336Z"
        fill="#C8D643" />
      <path
        d="M21.7845 16.3197C20.1394 16.5425 17.7061 16.8166 15.2556 16.9023C10.9202 17.0394 8.04133 16.6795 8.04133 16.6795L1.95801 22.4716C1.95801 22.4716 5.1796 23.1056 8.81246 23.5169C11.0402 23.7739 13.0108 23.911 14.2103 23.9795C14.296 23.9795 14.4331 23.911 14.5188 23.911C14.6045 23.911 15.4441 23.7568 16.2324 23.0028C17.0892 22.1802 22.2643 16.2683 21.7845 16.3197"
        fill="#63308C" />
      <path
        d="M21.7849 16.3197C20.1399 16.5424 17.7065 16.8166 15.2561 16.9023C15.2561 16.9023 15.7188 23.6711 14.0908 23.9624C14.1765 23.9624 15.2903 23.911 16.0786 23.157C16.9354 22.3344 22.2647 16.2683 21.7849 16.3197"
        fill="#262424" />
      <path
        d="M14.4502 23.9965C14.3303 23.9965 14.2103 23.9794 14.0732 23.9794C14.3817 23.928 14.5873 23.8252 14.9129 23.5852C15.5641 23.1226 15.7697 22.3172 15.8383 21.9916C16.1296 20.3637 16.5237 17.9475 16.7122 15.497C17.0549 11.1787 16.8493 8.29982 16.8493 8.29982L22.9326 2.50781C22.9326 2.50781 23.3782 5.72941 23.6352 9.3794C23.7894 11.7613 23.8408 13.8691 23.858 15.0343C23.858 15.12 23.9265 15.9597 23.1383 16.7137L16.1981 23.3454C15.3756 24.0993 14.5359 23.9965 14.4502 23.9965"
        fill="#81B950" />
    </g>
    <defs>
      <clipPath id="clip0_1084_22356">
        <rect width="24" height="24" fill="white" transform="translate(0 0.000976562)" />
      </clipPath>
    </defs>
  </svg>
);

export default Dynatrace;
