import {IIconProps} from '../IntegrationIcon';

const Cypress = ({color, width = '16', height = '16'}: IIconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_748_3566)">
      <path
        d="M7.99867 0C3.57734 0 1.78031e-06 3.578 1.78031e-06 8C-0.000699436 9.05077 0.205748 10.0914 0.607537 11.0623C1.00933 12.0332 1.59858 12.9154 2.34158 13.6584C3.08459 14.4014 3.96678 14.9907 4.93771 15.3925C5.90863 15.7943 6.94923 16.0007 8 16C12.422 16 16 12.422 16 8C15.9993 3.578 12.392 0 7.99867 0ZM4.24667 9.71667C4.508 10.0653 4.85734 10.2113 5.35134 10.2113C5.58467 10.2113 5.81734 10.182 6.02067 10.0947C6.22467 10.008 6.45734 9.89067 6.74734 9.71667L7.56267 10.88C6.894 11.4333 6.13734 11.6947 5.26467 11.6947C4.566 11.6947 3.98467 11.5493 3.46134 11.2587C2.95329 10.959 2.54758 10.5128 2.29734 9.97867C2.036 9.42533 1.89 8.78533 1.89 8.02867C1.89 7.302 2.036 6.63267 2.29667 6.08C2.54556 5.52941 2.9508 5.06412 3.462 4.742C3.956 4.422 4.56667 4.27667 5.236 4.27667C5.702 4.27667 6.138 4.33467 6.48734 4.48C6.87925 4.6317 7.24296 4.84803 7.56334 5.12L6.74867 6.22533C6.54185 6.07263 6.31733 5.94549 6.08 5.84667C5.87667 5.76 5.61467 5.73067 5.38134 5.73067C4.39267 5.73067 3.898 6.48667 3.898 8.028C3.86934 8.81467 4.01467 9.36667 4.24734 9.716L4.24667 9.71667ZM12.2467 11.52C11.956 12.422 11.5193 13.0913 10.908 13.5853C10.2973 14.0807 9.48267 14.342 8.46467 14.4293L8.26134 13.0627C8.93 12.9747 9.42467 12.8293 9.74467 12.596C9.86067 12.5093 10.094 12.2473 10.094 12.2473L7.67934 4.50933H9.686L11.0827 10.298L12.5667 4.50933H14.5153L12.2467 11.52Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_748_3566">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Cypress;
