import {IIconProps} from '../DataStoreIcon';

const Signoz = ({color, width = '29', height = '24'}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4C5.70606 4 3.28737 7.703 3.18649 7.86063C2.9378 8.24892 2.9378 8.75105 3.18668 9.13964C3.28737 9.29697 5.70606 13 10 13C14.2939 13 16.7127 9.29697 16.8135 9.13932C17.0622 8.75105 17.0622 8.24892 16.8133 7.86035C16.7126 7.703 14.2939 4 10 4ZM3.78084 8.72687C3.69254 8.5891 3.69254 8.41095 3.78084 8.27308C3.86196 8.14628 5.58402 5.512 8.66288 4.8801C7.26769 5.44097 6.27493 6.85156 6.27493 8.50001C6.27493 10.1484 7.26769 11.559 8.66288 12.1199C5.58402 11.488 3.86196 8.85368 3.78084 8.72687ZM8.28341 8.50001C8.28341 8.70538 8.12353 8.87184 7.92629 8.87184C7.72904 8.87184 7.56917 8.70538 7.56917 8.50001C7.56917 7.10413 8.65961 5.96851 9.99997 5.96851C10.1972 5.96851 10.3571 6.13499 10.3571 6.34042C10.3571 6.54585 10.1972 6.71231 9.99997 6.71231C9.05344 6.71231 8.28341 7.51422 8.28341 8.50001ZM10 9.40411C9.52126 9.40411 9.1318 8.99854 9.1318 8.50001C9.1318 8.00144 9.52126 7.59582 10 7.59582C10.4788 7.59582 10.8683 8.00142 10.8683 8.50001C10.8683 8.99854 10.4788 9.40411 10 9.40411ZM16.2191 8.72687C16.138 8.85368 14.416 11.488 11.3372 12.1199C12.7324 11.559 13.7251 10.1484 13.7251 8.50001C13.7251 6.85158 12.7324 5.44098 11.3372 4.88011C14.416 5.51205 16.138 8.14631 16.2191 8.27309C16.3075 8.41095 16.3075 8.5891 16.2191 8.72687Z"
        fill={color}
      />
    </svg>
  );
};

export default Signoz;
