import {IIconProps} from '../DataStoreIcon';

const Dynatrace = ({color, width = '20', height = '20'}: IIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.78697 1.67468C6.52993 3.03129 6.21577 5.04478 6.0444 7.08684C5.74452 10.6854 5.93017 13.0988 5.93017 13.0988L0.860729 17.9112C0.860729 17.9112 0.475166 15.2122 0.275245 12.1706C0.161007 10.2856 0.118164 8.62909 0.118164 7.62948C0.118164 7.57236 0.146724 7.51524 0.146724 7.45812C0.146724 7.38672 0.232403 6.71555 0.88929 6.08723C1.60329 5.40179 6.87265 1.27484 6.78697 1.67468"
        fill={color}
      />
      <path
        d="M0.118197 7.44385V7.75801C0.175316 7.51525 0.275278 7.34389 0.4752 7.07257C0.889323 6.54421 1.56049 6.4014 1.83181 6.37284C3.2027 6.1872 5.23047 5.973 7.27253 5.91588C10.8854 5.80164 13.2702 6.10152 13.2702 6.10152L18.3396 1.28913C18.3396 1.28913 15.6835 0.789325 12.6561 0.432338C10.6712 0.189576 8.92902 0.0610548 7.94369 0.00393413C7.87229 0.00393413 7.17257 -0.0817448 6.51568 0.546579L0.717962 6.05868C0.0610778 6.687 0.118197 7.38673 0.118197 7.44385Z"
        fill={color}
      />
      <path
        d="M18.1539 13.5984C16.783 13.7841 14.7552 14.0126 12.7132 14.084C9.10033 14.1982 6.70127 13.8983 6.70127 13.8983L1.63184 18.725C1.63184 18.725 4.31649 19.2534 7.34388 19.5961C9.20029 19.8103 10.8425 19.9245 11.8421 19.9816C11.9135 19.9816 12.0277 19.9245 12.0992 19.9245C12.1706 19.9245 12.8703 19.796 13.5272 19.1677C14.2412 18.4822 18.5538 13.5556 18.1539 13.5984"
        fill={color}
      />
      <path
        d="M12.0419 19.9961C11.942 19.9961 11.842 19.9818 11.7278 19.9818C11.9848 19.939 12.1562 19.8533 12.4275 19.6534C12.9702 19.2678 13.1415 18.5967 13.1986 18.3253C13.4414 16.9687 13.7698 14.9552 13.9269 12.9132C14.2125 9.3146 14.0412 6.91554 14.0412 6.91554L19.1106 2.08887C19.1106 2.08887 19.4819 4.77353 19.6961 7.81519C19.8246 9.80012 19.8674 11.5566 19.8817 12.5276C19.8817 12.599 19.9388 13.2987 19.282 13.9271L13.4985 19.4535C12.8131 20.0818 12.1133 19.9961 12.0419 19.9961"
        fill={color}
      />
    </svg>
  );
};

export default Dynatrace;