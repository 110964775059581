import {IIconProps} from '../DataStoreIcon';

const AWSXRay = ({color, width = '20', height = '20'}: IIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.86537 0.833867C4.96301 1.28776 4.21183 1.67085 4.19616 1.68529C4.18041 1.69965 4.42569 2.16617 4.74119 2.72194C5.05669 3.2778 5.30074 3.74736 5.28359 3.76557C5.26645 3.7837 4.47819 3.59978 3.53186 3.35672L1.81137 2.91489L0.905646 3.36172C0.172188 3.72365 0 3.84211 0 3.98501C0 4.08197 0.474316 5.35685 1.05396 6.81811C1.63369 8.27928 2.19324 9.71544 2.29742 10.0096L2.48692 10.5443L2.21432 11.2401C2.06445 11.6227 1.5049 13.0343 0.970863 14.3769C0.436909 15.7195 0 16.9021 0 17.0049C0 17.1611 0.147004 17.2645 0.891947 17.632L1.78398 18.0722L3.52013 17.6389C4.47499 17.4007 5.26899 17.2197 5.2845 17.2369C5.29992 17.254 5.05989 17.711 4.75103 18.2524C4.27508 19.0868 4.20797 19.2507 4.3106 19.3286C4.55268 19.512 7.53155 21.0005 7.65665 21.0005C7.72736 21.0005 8.08946 20.8667 8.46139 20.7032L9.13751 20.4059L9.75735 20.6697C10.0983 20.8149 10.4545 20.9518 10.5489 20.9742C10.7166 21.0138 11.896 20.4646 13.4359 19.6297L14.1157 19.2613L13.7335 18.5517C13.5233 18.1615 13.2639 17.6919 13.1571 17.5082L12.9628 17.1741L14.7321 17.6251L16.5015 18.076L17.4049 17.631C17.9018 17.3862 18.3237 17.1614 18.3424 17.1314C18.3866 17.0608 18.1279 16.3647 16.8608 13.1454L15.8194 10.4993L17.0465 7.36207C17.7214 5.6365 18.2986 4.14251 18.3294 4.0421C18.3802 3.87607 18.2991 3.81668 17.4371 3.38756L16.4889 2.91562L14.7985 3.34966C13.8689 3.58838 13.0908 3.76631 13.0694 3.74506C13.0482 3.72373 13.2783 3.2586 13.5808 2.71144C13.8834 2.1642 14.1168 1.70358 14.0998 1.68775C13.9992 1.59481 10.7286 0 10.6386 0C10.5783 0 10.222 0.133058 9.84669 0.29573L9.16417 0.591541L8.48322 0.29573C8.10873 0.133058 7.73565 0.0019688 7.65419 0.00434776C7.57273 0.00680875 6.76774 0.38006 5.86537 0.833867Z"
        fill={color}
      />
      <path d="M7.62898 0.0820312L4.38867 1.76371L9.18761 10.4592V2.05083L7.62898 0.0820312Z" fill="#243B6F" />
      <path d="M7.62898 20.877L4.38867 19.1953L9.18761 10.4998V18.9082L7.62898 20.877Z" fill="#243B6F" />
      <path d="M5.45516 3.93752L1.88672 3.07617L5.45516 10.5002H9.22869L5.45516 3.93752Z" fill="#324C87" />
      <path d="M5.45516 17.0625L1.88672 17.9238L5.45516 10.4998H9.22869L5.45516 17.0625Z" fill="#0D1936" />
      <path d="M13.0021 3.93752L16.5706 3.07617L13.0021 10.5002H9.22859L13.0021 3.93752Z" fill="#324C87" />
      <path d="M13.0021 17.0625L16.5706 17.9238L13.0021 10.4998H9.22859L13.0021 17.0625Z" fill="#0D1936" />
      <path d="M9.22852 0.615248V1.92778L10.6641 0.0410156L9.22852 0.615248Z" fill="#0D1936" />
      <path d="M9.26978 20.3852L9.26978 19.0727L7.83419 20.9595L9.26978 20.3852Z" fill="#243B6F" />
      <path d="M9.26978 0.656264V1.96879L7.83419 0.0820312L9.26978 0.656264Z" fill="#243B6F" />
      <path d="M9.22852 20.3437L9.22852 19.0312L10.6641 20.918L9.22852 20.3437Z" fill="#0D1936" />
    </svg>
  );
};

export default AWSXRay;
