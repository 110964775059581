import {IIconProps} from '../IntegrationIcon';

const Playwright = ({color, width = '16', height = '16'}: IIconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.4578 8.86284C4.94236 9.00912 4.6042 9.2656 4.38144 9.52188C4.5948 9.33516 4.8806 9.1638 5.26612 9.05452C5.66044 8.94276 5.99684 8.94356 6.2748 8.9972V8.77984C6.03768 8.75816 5.76584 8.77544 5.4578 8.86284ZM4.35788 7.03564L2.44362 7.53996C2.44362 7.53996 2.47851 7.58924 2.54311 7.655L4.16616 7.22732C4.16616 7.22732 4.14316 7.52368 3.94343 7.7888C4.32124 7.50296 4.35788 7.03564 4.35788 7.03564ZM5.96024 11.5345C3.26637 12.26 1.84113 9.13812 1.40962 7.51772C1.21026 6.76976 1.12323 6.20328 1.10004 5.83772C1.09755 5.79976 1.0987 5.76776 1.10138 5.73844C0.961639 5.74688 0.894735 5.81952 0.908347 6.02944C0.931543 6.3948 1.01857 6.96124 1.21793 7.70944C1.64924 9.3296 3.07467 12.4515 5.76856 11.726C6.35492 11.568 6.79544 11.2803 7.12612 10.913C6.82132 11.1883 6.43984 11.4051 5.96024 11.5345ZM6.46648 5.125V5.31672H7.52312C7.50144 5.24884 7.4796 5.18768 7.45792 5.125H6.46648Z"
      fill={color}
    />
    <path
      d="M7.75929 6.70255C8.23449 6.83751 8.48581 7.17067 8.61865 7.46551L9.14849 7.61599C9.14849 7.61599 9.07621 6.58411 8.14285 6.31899C7.26969 6.07091 6.73237 6.80415 6.66701 6.89903C6.92101 6.71807 7.29193 6.56991 7.75929 6.70255ZM11.9769 7.47027C11.103 7.22107 10.5658 7.95583 10.5014 8.04939C10.7556 7.86863 11.1264 7.72043 11.5935 7.85367C12.068 7.98883 12.3191 8.32159 12.4523 8.61663L12.9829 8.76767C12.9829 8.76767 12.9095 7.73559 11.9769 7.47027ZM11.4505 10.191L7.04293 8.95879C7.04293 8.95879 7.09065 9.20071 7.27373 9.51395L10.9847 10.5514C11.2902 10.3746 11.4505 10.191 11.4505 10.191ZM8.39473 12.8433C4.90477 11.9076 5.32669 7.46091 5.89141 5.35379C6.12393 4.48543 6.36297 3.84 6.56121 3.40735C6.44293 3.383 6.34497 3.44531 6.24817 3.64217C6.03769 4.06907 5.76853 4.76415 5.50805 5.73719C4.94349 7.84427 4.52157 12.2908 8.01137 13.2265C9.65629 13.6671 10.9377 12.9974 11.893 11.9455C10.9862 12.7668 9.82861 13.2272 8.39473 12.8433Z"
      fill={color}
    />
    <path
      d="M6.46647 10.4912V9.59392L3.97332 10.3009C3.97332 10.3009 4.15755 9.23048 5.45779 8.86164C5.85211 8.74988 6.18855 8.75064 6.46647 8.80432V5.1238H7.71479C7.57887 4.7038 7.44739 4.38044 7.33695 4.15576C7.15427 3.78388 6.96699 4.0304 6.54183 4.386C6.24239 4.63616 5.48559 5.16984 4.34675 5.47672C3.20789 5.7838 2.28719 5.70236 1.90304 5.63584C1.35843 5.54188 1.07358 5.42228 1.10022 5.83652C1.12342 6.20188 1.21045 6.76836 1.40981 7.51652C1.84112 9.13672 3.26655 12.2586 5.96043 11.5331C6.66411 11.3435 7.16079 10.9687 7.50507 10.491H6.46647V10.4912ZM2.44342 7.53876L4.35787 7.03444C4.35787 7.03444 4.30207 7.77092 3.58438 7.96012C2.86648 8.14912 2.44342 7.53876 2.44342 7.53876Z"
      fill={color}
    />
    <path
      d="M13.6714 5.16641C13.1738 5.25365 11.9799 5.36233 10.5044 4.96685C9.0286 4.57161 8.0494 3.88035 7.66144 3.55543C7.11148 3.09479 6.86956 2.77466 6.63148 3.25888C6.421 3.68597 6.15184 4.38105 5.89132 5.35409C5.3268 7.46117 4.90488 11.9077 8.39464 12.8434C11.8837 13.7783 13.7412 9.71625 14.3057 7.60897C14.5662 6.63613 14.6805 5.89945 14.7119 5.42445C14.7478 4.88637 14.3782 5.04257 13.6714 5.16641ZM6.65984 6.90969C6.65984 6.90969 7.2098 6.05433 8.14256 6.31945C9.07592 6.58457 9.1482 7.61645 9.1482 7.61645L6.65984 6.90969ZM8.93676 10.748C7.29608 10.2674 7.04304 8.95905 7.04304 8.95905L11.4504 10.1913C11.4504 10.1911 10.5608 11.2226 8.93676 10.748ZM10.495 8.05925C10.495 8.05925 11.0442 7.20449 11.9768 7.47037C12.9094 7.73589 12.9828 8.76777 12.9828 8.76777L10.495 8.05925Z"
      fill={color}
    />
    <path
      d="M5.59518 9.84152L3.97327 10.3012C3.97327 10.3012 4.14946 9.29752 5.34426 8.89976L4.42586 5.45312L4.3465 5.47724C3.20765 5.78436 2.28694 5.70288 1.90279 5.63636C1.35819 5.54244 1.07334 5.4228 1.09998 5.83708C1.12318 6.20244 1.2102 6.76888 1.40956 7.51708C1.84088 9.13724 3.26631 12.2592 5.96018 11.5336L6.03954 11.5087L5.59518 9.84152ZM2.44337 7.53932L4.35782 7.03497C4.35782 7.03497 4.30202 7.77145 3.58432 7.96065C2.86644 8.14965 2.44337 7.53932 2.44337 7.53932Z"
      fill={color}
    />
    <path
      d="M9.01076 10.766L8.93656 10.7479C7.29588 10.2674 7.04284 8.95905 7.04284 8.95905L9.31556 9.59433L10.5188 4.97069L10.5042 4.96685C9.0284 4.57161 8.04924 3.88035 7.66124 3.55543C7.11128 3.09479 6.86936 2.77466 6.63128 3.25888C6.421 3.68597 6.15184 4.38105 5.89132 5.35409C5.3268 7.46117 4.90488 11.9077 8.39464 12.8433L8.46616 12.8595L9.01076 10.766ZM6.65984 6.90969C6.65984 6.90969 7.2098 6.05433 8.14256 6.31945C9.07592 6.58457 9.1482 7.61645 9.1482 7.61645L6.65984 6.90969Z"
      fill={color}
    />
    <path
      d="M5.67788 9.8173L5.24292 9.94074C5.34568 10.52 5.5268 11.0759 5.81108 11.5671C5.86056 11.5561 5.90964 11.5467 5.96004 11.5329C6.09212 11.4973 6.2146 11.4532 6.33252 11.4051C6.01488 10.9337 5.80476 10.3908 5.67788 9.8173ZM5.50804 5.7373C5.28452 6.57154 5.08456 7.7723 5.1396 8.9767C5.23812 8.93394 5.3422 8.8941 5.4578 8.8613L5.53832 8.8433C5.44016 7.55682 5.65236 6.24586 5.8914 5.3539C5.952 5.12826 6.01276 4.91838 6.07332 4.72266C5.97576 4.78474 5.87072 4.84838 5.75108 4.91394C5.6704 5.16298 5.58892 5.43518 5.50804 5.7373Z"
      fill={color}
    />
  </svg>
);

export default Playwright;
