import {IIconProps} from '../DataStoreIcon';

const Instana = ({color, width = '20', height = '20'}: IIconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7835 16.4707C16.3138 16.4707 16.7438 15.9563 16.7438 15.3218C16.7438 14.6873 16.3138 14.1729 15.7835 14.1729C15.2532 14.1729 14.8232 14.6873 14.8232 15.3218C14.8232 15.9563 15.2532 16.4707 15.7835 16.4707Z" fill={color}/>
    <path d="M16.8329 19.827C16.0009 19.9683 13.9699 20.0044 11.7139 20.0044C9.4579 20.0044 7.42676 19.9683 6.59492 19.827C3.98392 19.3836 3.06778 16.1135 3.06778 13.5752C3.06778 11.1365 3.35121 9.47359 3.76352 8.7642C3.82654 8.65554 3.93468 8.48335 4.0896 8.27107C4.08703 9.57116 4.08574 10.9385 4.08574 11.1418V14.7428C4.08574 17.3102 5.54835 18.724 8.20421 18.724H15.2234C17.8792 18.724 19.3418 17.3102 19.3418 14.7428V11.1418C19.3418 10.9387 19.3405 9.57129 19.338 8.27094C19.493 8.48322 19.6011 8.65554 19.6643 8.7642C20.0766 9.47359 20.3601 11.1365 20.3601 13.5752C20.3601 16.1135 19.4439 19.3836 16.8329 19.827ZM4.81795 11.1418C4.81795 10.8649 4.8204 9.47127 4.82491 7.98855C4.82633 7.56941 5.00084 7.16599 5.31597 6.88965C6.5752 5.7847 8.63289 4.62278 11.7139 4.62278C14.7954 4.62278 16.8533 5.78509 18.1126 6.89017C18.4271 7.16625 18.6014 7.56902 18.6027 7.98752C18.6072 9.47063 18.6098 10.8649 18.6098 11.1418V14.7429C18.6098 16.8987 17.4705 17.9919 15.2234 17.9919H8.20434C5.95731 17.9919 4.81795 16.8987 4.81795 14.7429V11.1418ZM21.6758 5.20947L13.4658 0.469506C12.3817 -0.156502 11.0461 -0.156502 9.96198 0.469506L1.75197 5.20947C0.667895 5.83535 0 6.99212 0 8.24388V17.7239C0 18.9758 0.667895 20.1325 1.75197 20.7585L7.36657 24C7.03971 23.3423 6.83633 22.7238 6.92114 22.0878C6.97578 21.6785 7.25843 20.9829 8.0578 20.4141C8.23463 20.2882 8.48325 20.3722 8.54357 20.5809C8.59771 20.7685 8.73703 20.9282 8.9515 20.9696C9.63525 21.1018 10.5354 21.179 11.7139 21.179C12.8903 21.179 13.7893 21.1022 14.4727 20.9704C14.6894 20.9286 14.8276 20.7706 14.8801 20.5841C14.9394 20.3734 15.1881 20.2852 15.3666 20.4119C16.1683 20.9811 16.452 21.6778 16.5066 22.0878C16.5913 22.7238 16.3881 23.3423 16.0612 24L21.6758 20.7585C22.7599 20.1325 23.4278 18.9758 23.4278 17.7239V8.24388C23.4278 6.99212 22.7599 5.83535 21.6758 5.20947Z" fill={color}/>
    <path d="M7.64429 16.4707C8.17467 16.4707 8.60464 15.9563 8.60464 15.3218C8.60464 14.6873 8.17467 14.1729 7.64429 14.1729C7.11392 14.1729 6.68408 14.6873 6.68408 15.3218C6.68408 15.9563 7.11392 16.4707 7.64429 16.4707Z" fill={color}/>
  </svg>
);

export default Instana;
