import {IIconProps} from '../DataStoreIcon';

const AzureAppInsights = ({color, width = '20', height = '20'}: IIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1849 5.71218V5.59486C12.1849 2.58462 9.71341 0.0826696 6.67999 0.0436452C6.60512 -0.0736719 4.88243 0.0826696 4.88243 0.0826696C2.14902 0.434621 0.0143844 2.81925 0.0143844 5.59486C0.0143844 5.67316 -0.285128 7.86218 1.84926 9.69974C2.82292 10.5988 3.8339 13.0227 3.9839 13.7263L4.09609 13.961H8.0656L8.17804 13.7263C8.3278 13.0227 9.37634 10.5988 10.3127 9.73877C12.4473 7.86218 12.1849 5.79023 12.1849 5.71218Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.17114 15.3682H8.14065V16.6974H4.17114V15.3682ZM5.407 19.5121H6.86748L8.10334 18.1438H4.17114L5.407 19.5121Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12949 13.7655H6.38046V8.80064H5.74388V13.7265H4.9951V8.80064H4.35851C3.60949 8.80064 2.9729 8.13601 2.9729 7.35406C2.9729 6.5721 3.57192 5.90771 4.35851 5.90771C5.1451 5.90771 5.74412 6.57235 5.74412 7.35406V8.01869H6.3807V7.35406C6.3807 6.5721 7.01729 5.90771 7.76631 5.90771C8.51534 5.90771 9.15192 6.57235 9.15192 7.35406C9.15192 8.13576 8.51534 8.80064 7.76631 8.80064H7.12973L7.12949 13.7655ZM4.35851 6.68967C4.02144 6.68967 3.72192 6.96332 3.72192 7.3543C3.72192 7.70625 4.02144 8.01893 4.35851 8.01893H4.9951V7.3543C4.95753 7.00259 4.69558 6.68967 4.35851 6.68967ZM7.76631 6.68967C7.42924 6.68967 7.12973 7.00235 7.12973 7.3543V8.01893H7.76631C8.10339 8.01893 8.4029 7.70625 8.4029 7.3543C8.4029 7.00235 8.14071 6.68967 7.76631 6.68967Z"
        fill="white"
        fillOpacity="0.83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.68013 0.0436452C6.60526 -0.0736719 4.88257 0.0826695 4.88257 0.0826695C2.14891 0.434621 0.0145232 2.81925 0.0145232 5.59486C0.0145232 5.67316 -0.247672 7.58852 1.47501 9.34779L9.56355 0.903645C8.70233 0.356328 7.72867 0.0436452 6.68013 0.0436452Z"
        fill="white"
        fillOpacity="0.15"
      />
    </svg>
  );
};

export default AzureAppInsights;
