import {IIconProps} from '../DataStoreIcon';

const OpenSearch = ({width = '24', height = '24'}: IIconProps) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1084_22173)">
      <path
        d="M21.2222 8.0791C20.7927 8.0791 20.4445 8.42732 20.4445 8.85687C20.4445 15.2571 15.2561 20.4454 8.85589 20.4454C8.42634 20.4454 8.07812 20.7937 8.07812 21.2232C8.07812 21.6528 8.42634 22.001 8.85589 22.001C16.1152 22.001 22 16.1161 22 8.85687C22 8.42732 21.6518 8.0791 21.2222 8.0791Z"
        fill="#005EB8"
      />
      <path
        d="M16.528 13.0635C17.2759 11.8433 17.9993 10.2165 17.857 7.93889C17.5622 3.22099 13.289 -0.358114 9.25396 0.0297594C7.67433 0.181608 6.05234 1.4692 6.19644 3.77552C6.25908 4.77776 6.74961 5.36929 7.54683 5.82407C8.30563 6.25695 9.2805 6.53116 10.3857 6.84198C11.7206 7.21746 13.2691 7.63921 14.4592 8.51625C15.8856 9.5674 16.8606 10.7859 16.528 13.0635Z"
        fill="#003B5C"
      />
      <path
        d="M1.34702 4.81348C0.599074 6.03362 -0.124268 7.66048 0.0180445 9.93807C0.312837 14.656 4.586 18.2351 8.62104 17.8472C10.2007 17.6953 11.8227 16.4078 11.6786 14.1014C11.6159 13.0992 11.1254 12.5077 10.3282 12.0529C9.56938 11.62 8.59451 11.3458 7.48935 11.035C6.15443 10.6595 4.60594 10.2377 3.41583 9.3607C1.98944 8.30955 1.01438 7.09106 1.34702 4.81348Z"
        fill="#005EB8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1084_22173">
        <rect width="22" height="22" fill="white" transform="translate(0 0.000976562)" />
      </clipPath>
    </defs>
  </svg>
);

export default OpenSearch;
