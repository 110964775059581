import {IIconProps} from '../DataStoreIcon';

const Signoz = ({width = '29', height = '24'}: IIconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1084_22346)">
      <path
        opacity="0.9"
        d="M12.0335 23.9809C5.34818 23.9809 0 18.7354 0 12.1785C0 5.6871 5.34818 0.375977 12.0335 0.375977H19.5877C22.0613 0.375977 24 2.34306 24 4.70356V12.1785C24 18.7354 18.6518 23.9809 12.0335 23.9809Z"
        fill="#F25733" />
      <path
        d="M11.9391 7.06934C6.94568 7.06934 4.13301 11.2043 4.0157 11.3803C3.7265 11.8139 3.7265 12.3746 4.01592 12.8085C4.13301 12.9842 6.94568 17.1191 11.9391 17.1191C16.9324 17.1191 19.7452 12.9842 19.8625 12.8081C20.1517 12.3746 20.1517 11.8139 19.8622 11.38C19.7451 11.2043 16.9324 7.06934 11.9391 7.06934ZM4.70687 12.3476C4.60418 12.1937 4.60418 11.9948 4.70687 11.8408C4.8012 11.6992 6.80377 8.7577 10.3841 8.05209C8.76169 8.67838 7.60722 10.2535 7.60722 12.0942C7.60722 13.9349 8.76169 15.51 10.3841 16.1363C6.80377 15.4307 4.8012 12.4892 4.70687 12.3476ZM9.94287 12.0942C9.94287 12.3236 9.75695 12.5094 9.52758 12.5094C9.2982 12.5094 9.11228 12.3236 9.11228 12.0942C9.11228 10.5355 10.3803 9.26746 11.939 9.26746C12.1684 9.26746 12.3543 9.45335 12.3543 9.68274C12.3543 9.91214 12.1684 10.098 11.939 10.098C10.8383 10.098 9.94287 10.9935 9.94287 12.0942ZM11.9391 13.1038C11.3824 13.1038 10.9295 12.6509 10.9295 12.0942C10.9295 11.5375 11.3824 11.0846 11.9391 11.0846C12.4959 11.0846 12.9488 11.5375 12.9488 12.0942C12.9488 12.6509 12.4959 13.1038 11.9391 13.1038ZM19.1713 12.3476C19.0769 12.4892 17.0744 15.4307 13.4941 16.1363C15.1166 15.51 16.271 13.9349 16.271 12.0942C16.271 10.2535 15.1166 8.67839 13.4941 8.0521C17.0744 8.75776 19.0769 11.6993 19.1713 11.8408C19.274 11.9948 19.274 12.1937 19.1713 12.3476Z"
        fill="#F9F2F9" />
    </g>
    <defs>
      <clipPath id="clip0_1084_22346">
        <rect width="24" height="24" fill="white" transform="translate(0 0.000976562)" />
      </clipPath>
    </defs>
  </svg>
);

export default Signoz;
