import {IIconProps} from '../DataStoreIcon';

const Otlp = ({color, height = '28', width = '28'}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_976_22283)">
        <path
          d="M14.7981 15.2682C13.6501 16.4162 13.6501 18.2775 14.7981 19.4255C15.9461 20.5735 17.8075 20.5735 18.9555 19.4255C20.1035 18.2775 20.1035 16.4162 18.9555 15.2682C17.8075 14.1202 15.9461 14.1202 14.7981 15.2682ZM17.9059 18.3787C17.3374 18.9472 16.4162 18.9472 15.8477 18.3787C15.2792 17.8101 15.2792 16.889 15.8477 16.3205C16.4162 15.752 17.3374 15.752 17.9059 16.3205C18.4744 16.8863 18.4744 17.8101 17.9059 18.3787ZM18.9063 0.80351L17.105 2.60476C16.7524 2.95736 16.7524 3.53682 17.105 3.88941L24.1378 10.9222C24.4904 11.2748 25.0699 11.2748 25.4225 10.9222L27.2237 9.12096C27.5763 8.76837 27.5763 8.18891 27.2237 7.83631L20.1882 0.80351C19.8356 0.450913 19.2589 0.450913 18.9063 0.80351ZM6.2866 23.8207C6.6064 23.5009 6.6064 22.9788 6.2866 22.659L5.37094 21.7434C5.05114 21.4236 4.52908 21.4236 4.20929 21.7434L2.31784 23.6348L2.3151 23.6376L1.79577 23.1182C1.50878 22.8312 1.04138 22.8312 0.757118 23.1182C0.470121 23.4052 0.470121 23.8726 0.757118 24.1569L3.87309 27.2729C4.16009 27.5599 4.62748 27.5599 4.91175 27.2729C5.19601 26.9859 5.19874 26.5185 4.91175 26.2342L4.39242 25.7149L4.39515 25.7121L6.2866 23.8207ZM15.8149 5.97493L11.8133 9.97649C11.458 10.3318 11.458 10.9167 11.8133 11.2721L14.2843 13.743C16.0308 12.4857 18.4799 12.6415 20.0515 14.2131L22.0523 12.2123C22.4076 11.857 22.4076 11.2721 22.0523 10.9167L17.1105 5.97493C16.7552 5.61686 16.1702 5.61686 15.8149 5.97493ZM13.2456 14.7844L11.786 13.3248C11.4443 12.9831 10.884 12.9831 10.5424 13.3248L5.39554 18.4743C5.05388 18.816 5.05388 19.3763 5.39554 19.718L8.31198 22.6344C8.65364 22.9761 9.21397 22.9761 9.55563 22.6344L12.8657 19.3189C12.1659 17.873 12.2917 16.1264 13.2456 14.7844Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_976_22283">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Otlp;
