import {IIconProps} from '../DataStoreIcon';

const SumoLogic = ({width = '20', height = '20'}: IIconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1084_22366)">
      <path
        d="M12 24.001C18.6274 24.001 24 18.6284 24 12.001C24 5.37356 18.6274 0.000976562 12 0.000976562C5.37258 0.000976562 0 5.37356 0 12.001C0 18.6284 5.37258 24.001 12 24.001Z"
        fill="#000099"
      />
      <path
        d="M8.27119 8.47109C7.97353 8.40781 7.76025 8.35156 7.64072 8.32344C7.49307 8.28125 7.37822 8.225 7.32197 8.14531V7.81953C7.42041 7.66484 7.66182 7.56406 7.99463 7.56406C8.454 7.56406 8.77275 7.66953 9.27666 8.06797L9.86494 7.36016C9.26963 6.86328 8.76103 6.6875 8.02275 6.6875C7.17197 6.6875 6.58604 7.03437 6.35166 7.53125V8.60703C6.53682 8.96797 6.96104 9.17422 7.80947 9.35703C8.11416 9.42734 8.32041 9.47656 8.43994 9.49766C8.59463 9.54688 8.7376 9.61016 8.81494 9.725V10.1C8.70947 10.2711 8.454 10.3625 8.12119 10.3625C7.87979 10.3695 7.64775 10.3203 7.42744 10.2289C7.21416 10.1445 6.96807 9.98047 6.65635 9.725L6.02588 10.4258C6.70557 11.0281 7.27978 11.232 8.09307 11.232C8.96494 11.232 9.58135 10.8992 9.81338 10.3672V9.28438C9.6165 8.88125 9.14072 8.67031 8.27119 8.47109ZM17.6813 6.82812V11.1055H16.6829V10.6531C16.4556 11.0211 16.0384 11.2344 15.429 11.2344C14.4165 11.2344 13.9126 10.7094 13.9126 9.875V6.82812H15.0024V9.58203C15.0024 10.0273 15.2438 10.2898 15.7103 10.2898C16.2563 10.2898 16.5892 9.97813 16.5892 9.40391V6.82578H17.6813V6.82812ZM12.4642 14.225V17.2133H11.3743V14.5508C11.3743 14.0398 11.1681 13.7281 10.6946 13.7281C10.2212 13.7281 9.94463 14.082 9.94463 14.5789V17.2133H8.85478V14.5508C8.85478 14.0047 8.62744 13.7281 8.1751 13.7281C7.69463 13.7281 7.4251 14.082 7.4251 14.5789V17.2133H6.33525V12.9359H7.34775V13.4094C7.59619 12.9992 8.00635 12.793 8.5665 12.793C9.1126 12.793 9.52275 13.0203 9.75713 13.4234C10.0478 13.0063 10.479 12.793 11.0392 12.793C11.9485 12.7953 12.4642 13.3414 12.4642 14.225ZM15.7407 12.7531C14.7563 12.7531 13.9853 13.2852 13.6665 14.1336V15.9336C13.9782 16.7844 14.7563 17.3141 15.7407 17.3141C16.7251 17.3141 17.4892 16.782 17.8009 15.9406V14.1266C17.4845 13.2852 16.711 12.7531 15.7407 12.7531ZM16.7485 15.6641C16.5774 16.1164 16.2165 16.3859 15.736 16.3859C15.2626 16.3859 14.8853 16.1164 14.7165 15.6641V14.4031C14.8876 13.9508 15.2485 13.6742 15.736 13.6742C16.2165 13.6742 16.5728 13.9508 16.7415 14.4031L16.7485 15.6641Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1084_22366">
        <rect width="24" height="24" fill="white" transform="translate(0 0.000976562)" />
      </clipPath>
    </defs>
  </svg>
);

export default SumoLogic;
