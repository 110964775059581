import {IIconProps} from '../DataStoreIcon';

const Otlp = ({height = '28', width = '28'}: IIconProps) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1083_21957)">
      <path
        d="M11.6394 12.0252C10.7021 12.9623 10.7021 14.4819 11.6394 15.4191C12.5767 16.3562 14.0964 16.3562 15.0336 15.4191C15.9709 14.4819 15.9709 12.9623 15.0336 12.0252C14.0964 11.088 12.5767 11.088 11.6394 12.0252ZM14.1767 14.5644C13.7125 15.0286 12.9605 15.0286 12.4963 14.5644C12.0322 14.1003 12.0322 13.3484 12.4963 12.8842C12.9605 12.4201 13.7125 12.4201 14.1767 12.8842C14.6409 13.3461 14.6409 14.1003 14.1767 14.5644ZM14.9935 0.21686L13.5229 1.68732C13.235 1.97516 13.235 2.44821 13.5229 2.73605L19.2647 8.47732C19.5526 8.76516 20.0257 8.76516 20.3136 8.47732L21.7842 7.00686C22.0721 6.71901 22.0721 6.24597 21.7842 5.95812L16.0401 0.21686C15.7522 -0.0709845 15.2813 -0.0709845 14.9935 0.21686ZM4.69024 19.0071C4.95133 18.746 4.95133 18.3198 4.69024 18.0587L3.94265 17.3112C3.68156 17.0502 3.25533 17.0502 2.99423 17.3112L1.44997 18.8553L1.44774 18.8576L1.02374 18.4336C0.789424 18.1993 0.407822 18.1993 0.175737 18.4336C-0.0585791 18.6679 -0.0585791 19.0495 0.175737 19.2815L2.71975 21.8253C2.95406 22.0595 3.33566 22.0595 3.56775 21.8253C3.79983 21.591 3.80206 21.2094 3.56775 20.9773L3.14375 20.5534L3.14598 20.5512L4.69024 19.0071Z"
        fill="#F5A800" />
      <path
        d="M12.4694 4.43896L9.20239 7.70565C8.91229 7.99573 8.91229 8.47324 9.20239 8.76331L11.2197 10.7805C12.6457 9.75403 14.6452 9.88122 15.9284 11.1642L17.5619 9.5309C17.852 9.24082 17.852 8.76331 17.5619 8.47324L13.5272 4.43896C13.2371 4.14665 12.7595 4.14665 12.4694 4.43896ZM10.3717 11.6306L9.18008 10.4391C8.90113 10.1601 8.44365 10.1601 8.16471 10.4391L3.96263 14.6429C3.68368 14.9218 3.68368 15.3793 3.96263 15.6582L6.34373 18.039C6.62268 18.318 7.08015 18.318 7.3591 18.039L10.0616 15.3324C9.49027 14.152 9.59292 12.7262 10.3717 11.6306Z"
        fill="#425CC7" />
    </g>
    <defs>
      <clipPath id="clip0_1083_21957">
        <rect width="22" height="22" fill="white" transform="translate(0 0.000976562)" />
      </clipPath>
    </defs>
  </svg>
);

export default Otlp;
